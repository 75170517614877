import axios from '@axios'

const getUser = async () => {
  const { status, data } = await axios.get('/merchant/me')

  if (status === 200) {
    return data.data
  }

  return null
}

// currently it does not need to fetch user.
const fetchUser = () => {}
const fetchUsers = () => {}

export {
  fetchUser,
  fetchUsers,
  getUser,
}
